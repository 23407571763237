
<script setup lang="ts">
    const WIDTH: number = 5;
    const HEIGHT: number = 5;

    const grid = [
        [0, 2, 2, 2, 2],
        [0, 0, 2, 2, 2],
        [1, 0, 0, 2, 2],
        [1, 1, 0, 0, 2],
        [1, 1, 1, 0, 0],
    ];

    const A = [3, 2];
    const B = [1, 3];

    function getClass(num) {
        const classes = ["d-flex justify-content-center align-items-center position-relative"];
        return classes.join(" ");
    }

    function getStyle(num) {
        const style = {};
        style.width = "2rem";
        style.height = "2rem";
        if (num === 0) {
            style.backgroundColor = "#F0E68C";
        } else if (num === 1) {
            style.backgroundColor = "#8FBC8F";
        } else if (num === 2) {
            style.backgroundColor = "#FF6347";
        }
        return style;
    }
</script>

<template>
    <div class="p-3">
    <button class="btn btn-primary">
        Opprett tiltak
    </button>
    </div>
</template>
